var MantleGrid = MantleGrid || {};
var MantleCarousel = MantleCarousel || {};
var MantleWindow = MantleWindow || {};
var MantlePerf = MantlePerf || {};
var MantleBehavior = MantleBehavior || {};
var prodcat = prodcat || {};

(function ($) {
  var _init_shade_hover = function ($grid) {
    // product image change on swatch hover
    // note this works on a delay when restoring the original images so that
    // we don't switch back original image when moving mouse between swatches.
    var __restore_default_timeout = null;

    $grid.on('shadePickerShade:hover-in', '.js-product', function (e, skuBaseId) {
      var $product = $(this);
      var is_nearest = prodcat.ui.isNearestValidProduct($product, e.target, skuBaseId);

      if (!is_nearest) {
        return;
      }

      window.clearTimeout(__restore_default_timeout);

      var sku = prodcat.data.getSku(skuBaseId);
      var medium_image = sku.IMAGE_M;
      var $images = $('.product__image-link img', $product);

      $images.each(function (i, obj) {
        var $image = $(obj);

        $image.attr('src', medium_image);
      });
    });

    $grid.on('shadePickerShade:hover-out', '.js-product', function (e, skuBaseId) {
      // reset image to original
      var $product = $(this);
      var is_nearest = prodcat.ui.isNearestValidProduct($product, e.target, skuBaseId);

      if (!is_nearest) {
        return;
      }

      var _restore_default_images = function () {
        var $images = $('.product__image-link img', $product);

        $images.each(function (i, obj) {
          var $image = $(obj);
          var original_source = $image.data('src');

          $image.attr('src', original_source);
        });
      };

      window.clearTimeout(__restore_default_timeout);
      __restore_default_timeout = window.setTimeout(_restore_default_images, 100);
    });
  };

  /*
   * Calcualtes the height of the image wrapper and padding of slide, then sets
   * the arrow top to be placed in middle of image.
   */
  function _align_arrows_to_middle_of_product_carousel($carousel) {
    var $gridItem = $('.js-grid-item[data-product-id]', $carousel).first();

    if (!$gridItem.length) {
      return;
    }

    var $brief = $('.js-product-brief', $gridItem);
    var $image_wrapper = $('.product-brief__image-wrapper', $brief);
    var $arrows = $('.slick-arrow', $carousel);
    var paddingTop = $gridItem.css('padding-top');
    var imageHeight = $image_wrapper.height();

    if (imageHeight <= 0) {
      return;
    }

    var cssTop = 'calc((' + imageHeight + 'px / 2) + ' + paddingTop + ')';

    $arrows.css('top', cssTop);
  }

  var carouselIsInfinite = function ($carousel, gridItemsPerRow) {
    var $slideItems = $carousel.find('.js-product-grid-item:not(.slick-cloned)');
    var slidesToShowCount = _get_slidesToShow(gridItemsPerRow);
    var totalCols = MantleGrid.gather_total_cols($slideItems);
    var infiniteHurdle = slidesToShowCount + 2;

    // For now. If the number of items is 2 or less. Never do infinite.
    // Could make the case that 3 items should also not show infinite.
    if ($slideItems.length <= 2) {
      return false;
    }

    // For now, any longer carousel will be infinite.
    if (slidesToShowCount > 2) {
      return true;
    }

    // single col really treats wide and single col product briefs the same.
    if (slidesToShowCount === 1) {
      totalCols = $slideItems.length;
    }

    if (totalCols >= infiniteHurdle) {
      return true;
    }

    return false;
  };
  var _init_carousel = function ($grid, $carousel, gridItemsPerRow, centerArrowsOnImage) {
    /*
     * Note that Product Carousels for TooFaced are different because they have
     * wide products that take up two column slots. So these carousels use
     * variableWidth where the slidesToShow is just 1. The # of items being
     * shown is handled via resizing the carousel items.
     */
    var isInfinite = carouselIsInfinite($carousel, gridItemsPerRow);
    var settings = {
      arrows: true,
      dots: false,
      infinite: isInfinite,
      focusOnSelect: false,
      lazyLoad: 'progressive',
      mobileFirst: true,
      rows: 0,
      slidesToScroll: 1,
      slidesToShow: 1,
      swipe: true,
      swipeToSlide: true,
      variableWidth: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: gridItemsPerRow.large,
            variableWidth: true
          }
        }
      ]
    };
    var _resize_slide_items = function () {
      var slidesToShowCount = _get_slidesToShow(gridItemsPerRow);
      var carousel_width = $carousel.width();
      var slide_width = carousel_width / slidesToShowCount;

      $carousel.find('.js-product-grid-item').each(function (i, obj) {
        var $slide = $(obj);
        var new_width = slide_width;

        // Only multiple width if we have space.
        if ($slide.data('grid-colspan') === 2 && slidesToShowCount >= 2) {
          new_width = slide_width * 2;
        }

        $slide.css('width', new_width + 'px');
        $slide.attr('data-slide-width', new_width + 'px');
      });
    };
    var _mantle_carousel_fix_lazy_images_setup = function ($carousel) {
      var slidesToShowCount = _get_slidesToShow(gridItemsPerRow);

      $carousel.off('.fix_lazy_images');

      $carousel.on(
        'beforeChange.fix_lazy_images',
        function (event, slick, currentSlide, nextSlide) {
          // eslint-disable-next-line no-undef
          _mantle_carousel_check_range($carousel, nextSlide, slidesToShowCount);
        }
      );
    };
    var _colspan_check = function () {
      var slidesToShowCount = _get_slidesToShow(gridItemsPerRow);
      var $slideItems = $carousel.find('.js-product-grid-item:not(.slick-cloned)');
      var totalCols = MantleGrid.gather_total_cols($slideItems);
      var isInfinite = carouselIsInfinite($carousel, gridItemsPerRow);

      $carousel.attr('data-total-colspan', totalCols);

      if (totalCols <= slidesToShowCount || $slideItems.length <= 1) {
        $carousel.addClass('mantle-carousel-no-overflow');

        if (MantleCarousel.isCarousel($carousel)) {
          MantleCarousel.slick_method($carousel, 'unslick');
        }
        $grid.attr('data-carousel-active', 0);
      } else {
        $carousel.removeClass('mantle-carousel-no-overflow');
        MantleCarousel.setOptions($carousel, {
          infinite: isInfinite,
          draggable: true,
          accessibility: true,
          touchMove: true,
          arrows: true
        });

        if (!MantleCarousel.isCarousel($carousel)) {
          MantleCarousel.initializeCarousel($carousel, settings);
          _mantle_carousel_fix_lazy_images_setup($carousel);
          if (centerArrowsOnImage) {
            $carousel.off('.product-grid-carousel');
            $carousel.on('setPosition.product-grid-carousel', function () {
              _align_arrows_to_middle_of_product_carousel($carousel);
            });
          }
        } else {
          // Until DRUPALPLAT-2574 gets pushed. Trigger refresh manually.
          MantleCarousel.slick_method($carousel, 'slickSetOption', '__mantle', true, true);
        }
        $grid.attr('data-carousel-active', 1);
      }
      _resize_slide_items();
    };

    _resize_slide_items();
    _colspan_check(); // colspan check will initialize carousel

    var __position_timeout = null;
    var __old_width = $(window).width();

    MantleWindow.onResize(function () {
      window.clearTimeout(__position_timeout);
      var width = $(window).width();

      if (width === __old_width) {
        return;
      }

      __old_width = width;

      __position_timeout = window.setTimeout(function () {
        _resize_slide_items();
        _colspan_check();
        if (MantleCarousel.isCarousel($carousel)) {
          $carousel.slick('setPosition');
        }
      }, 100);
    });

    // Accessibility fix for https://github.com/kenwheeler/slick/issues/2812
    $carousel.on('init afterChange', function (event, slick, currentSlide) {
      if (slick.$slider.hasClass('js-product-carousel')) {
        setTimeout(function () {
          var slidesToShow = slick.options.slidesToShow;
          $(slick.$slides.get(currentSlide))
            .nextAll()
            .addBack()
            .slice(0, slidesToShow)
            .attr('aria-hidden', false);
        }, 100);
      }
    });
  };
  /* eslint-disable-next-line complexity */
  var _get_slidesToShow = function (gridItemsPerRow, breakpointName) {
    if (!breakpointName) {
      var breakpoint = Unison.fetch.now();

      breakpointName = breakpoint.name;
    }

    // short circuit, breakpoint setting already exists.
    if (gridItemsPerRow[breakpointName]) {
      return gridItemsPerRow[breakpointName];
    }

    // We only reach here if we don't explicitly have the breakpoint
    // specified.
    switch (breakpointName) {
      case 'small': {
        return 2;
      }
      case 'medium': {
        return 3;
      }
      case 'large': {
        // if medium is specified. Use that.
        // This is for backwards compat since large did not previously exist.
        if (gridItemsPerRow.medium) {
          return _get_slidesToShow(gridItemsPerRow, 'medium');
        }

        return 4;
      }
      case 'landscape': {
        // landscape is large
        return _get_slidesToShow(gridItemsPerRow, 'large');
      }
      default: {
        return 4;
      }
    }
  };

  Drupal.behaviors.productGrid = MantleBehavior.create({
    targetSelector: '.product-grid-wrapper',

    /*
     * For the attach / gatherTargets phase, only return non mpp product-grids
     */
    gatherTargets: function (context) {
      var $candidates = $(this.targetSelector, context);
      var targets = [];

      $candidates.each(function (i, obj) {
        var $wrapper = $(obj);

        // This allows mpp product grid layout to control
        if ($wrapper.closest('[data-disable-product-grid-attach=1]').length > 0) {
          return;
        }
        targets.push(obj);
      });

      return $(targets);
    },

    attachTarget: function (target) {
      var $wrapper = $(target);

      this.attachReal($wrapper);
    },

    /*
     * Lazily start product-grid carousels.
     */
    attachLazyTarget: function (target) {
      var $wrapper = $(target);
      var $grid = $('.js-product-grid.js-mantle-grid', $wrapper);
      var $carouselGrids = $grid.filter('.product-grid--carousel');
      var $carousels = $('.js-product-carousel', $carouselGrids);
      var hasQuickshop = $grid.hasClass('product-grid--quickshop');
      var centerArrowsOnImage = !!$grid.data('center-arrows-on-product-image');
      var gridItemsPerRow = $grid.data('grid-items-per-row') || {};

      $carousels.each(function (i, obj) {
        var $carousel = $(obj);

        // Since the carousels layout correctly. let everything else finish up
        // before initializing carousels.
        // TODO also make this lazy?
        _init_carousel($grid, $carousel, gridItemsPerRow, centerArrowsOnImage);
        // On before slide change
        $carousel.on('beforeChange', function (event) {
          // Remove quickshop:
          if (hasQuickshop && Drupal.behaviors.quickshop) {
            $('.js-quickshop', $wrapper).remove();
          }
        });
      });
    },

    initGrid: function ($wrapper) {
      var $grid = $('.js-product-grid.js-mantle-grid', $wrapper);

      MantleGrid.initializeGrid($grid, '.js-product-grid-item');
    },

    attachEventHandlers: function ($grid) {
      // product brief shades luaunch quickshop set to the shade sku clicked
      $grid.on('shadePicker:shadeClicked', '.js-product-brief', function (e, skuBaseId) {
        var $productBrief = $(this);
        var product_id = $productBrief.data('product-id');
        var $quickview = $('.js-quickshop-container--' + product_id);

        // If this fires twice on the same product ignore the second one.
        if ($quickview.hasClass('active')) {
          return;
        }
        $productBrief.trigger('product.quickshop.launch.inline', [skuBaseId]);
      });

      $grid.on('product.quickshop.opened', '.js-product', function () {
        $grid.addClass('quickshop-opened');
        $('body').addClass('quickshop-opened');
      });

      $grid.on('product.quickshop.closed', '.js-product', function () {
        $grid.removeClass('quickshop-opened');
        $('body').removeClass('quickshop-opened');
      });
    },

    attachReal: function ($wrapper) {
      var $grid = $('.js-product-grid.js-mantle-grid', $wrapper);
      var $products = $('.js-product-grid-item', $wrapper);
      var gridItemsPerRow = $grid.data('grid-items-per-row') || {};

      // This happens if the attr is not valid json. In this case default to
      // empty object (which will use default values) and warn.
      if (typeof gridItemsPerRow === 'string') {
        /* eslint-disable-next-line no-console */
        console.warn('data-grid-items-per-row was not valid json. ' + gridItemsPerRow);
        gridItemsPerRow = {};
      }

      var slidesToShowCount = _get_slidesToShow(gridItemsPerRow);
      var $slideItems = $grid.find('.js-product-grid-item:not(.slick-cloned)');
      var totalCols = MantleGrid.gather_total_cols($slideItems);

      // Set the carousel active as early as possbile
      // This is done before initGrid so it can get a proper column count
      if (totalCols <= slidesToShowCount) {
        $grid.attr('data-carousel-active', 0);
      }

      var self = this;

      MantlePerf.thresholdLog(function () {
        self.initGrid($wrapper);
      }, 'productGrid.initGrid');

      this.attachEventHandlers($grid);

      if ($grid.data('shade-hover-image-change')) {
        _init_shade_hover($grid);
      }

      $products.each(function () {
        var $product = $(this).hasClass('product-brief--sku-product')
          ? $(this)
          : $(this).find('.product-brief--sku-product');

        if ($product) {
          var skuBaseId = $product.attr('data-sku-base-id')
            ? $product.attr('data-sku-base-id')
            : $product.data('sku-base-id');

          $product.trigger('product.updateRoute', [skuBaseId]);
        }
      });
    }
  });
})(jQuery);
